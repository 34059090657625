import type { IHeadline } from '~/lib/ContentfulService';
import { mapHeadlineLevel } from '~/utils/mapHeadlineLevel';
import type { HeadlineProps } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import type { Props as HeadlineModuleProps } from '@hypercodestudio/basler-components/dist/components/modules/HeadlineModule.vue';

export function mapHeadline(
  headline: IHeadline
): HeadlineProps & HeadlineModuleProps {
  return {
    level: mapHeadlineLevel(headline.fields.headingDisplaySize ?? 'H2'),
    tag: headline.fields.headingMarkup?.toLowerCase(),
    styleType:
      headline.fields.headingDisplaySize === 'display-01'
        ? 'display-01'
        : undefined,
    modifier:
      headline.fields.headingStyleMode === 'gradient-01'
        ? 'gradient-01'
        : undefined,
    badgeText: headline.fields.badge?.fields?.title,
    badgeSize: headline.fields.badge?.fields?.size,
    badgeStyle: headline.fields.badge?.fields?.style,
    isCentered: headline.fields.headingPosition === 'centered',
    icon: headline.fields.headingIcon
      ? {
          name: headline.fields.headingIcon,
          scaleFactor: 1.15
        }
      : undefined,
    content: headline.fields.headlineText ?? ''
  };
}
