import type { Level } from '@hypercodestudio/basler-components/dist/components/modules/HeadlineModule.vue';

export function mapHeadlineLevel(
  level: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'display-01'
) {
  if (level === 'display-01') {
    return 2;
  }

  return parseInt(level.replace('H', '')) as Level;
}
